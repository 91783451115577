.<!-- ECharts组件 -->
<template>
  <div class="echarts-component" ref="chartEl"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  data() {
    return {
      chart: null,
      options:null
    };
  },

  components: {},

  computed: {},

  mounted() {
      this.initChart()
  },

  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.chartEl);
      window.onresize = () => this.chart.resize();
    },
    loadChart(options){
        
        this.options = options
        // this.chart.clear()
        this.chart.setOption(options)
    }
  },
};
</script>
<style lang="scss" scoped>
.echarts-component{
    width: 100%;
    height: 100%;
}
</style>
