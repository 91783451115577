function createEnumItem(type, desc) {
  return { type: type,desc:desc };
}

/**
 * 运动方向枚举
 */
export const SportDirectionEnum = {
  LEFT_FLEXION: {
    type: 0,
    desc: "左屈",
  },
  RIGHT_FLEXION: {
    type: 1,
    desc: "右屈",
  },
  LEFT_ROTATE: {
    type: 2,
    desc: "左旋",
  },
  RIGHT_ROTATE: {
    type: 3,
    desc: "右旋",
  },
  PROTRACT: {
    type: 4,
    desc: "前屈",
  },
  HYPSOKINESIS: {
    type: 5,
    desc: "后伸",
  },
};

export const EvaluationTypeEnum = {
  ACTIVE: {
    type: 0,
    desc: "主动评估",
  },
  PASSTIVE: {
    type: 1,
    desc: "被动评估",
  },
  SELF: {
    type: 2,
    desc: "本体感觉评估",
  },
};

/**
 * 运动项目类型枚举
 */
export const SportProjectTypeEnum = {
  NECK: createEnumItem(1, "标准颈部训练"),
  NECK_MUSCLE: createEnumItem(2, "肩颈肌肉训练"),
  SELFFEEL: createEnumItem(3, "本体感觉训练"),
  SELFFEEL_OPEN: createEnumItem(4, "运动速度训练"),
  SELFFEEL_OC: createEnumItem(5, "本体感觉训练 睁闭眼"),
  SELFFEEL_CLOSE: createEnumItem(6, "本体感觉训练 闭眼"),
};

/**
 * 训练速度枚举
 */
export const SportSpeedEnum = {
  SLOW:{
    type:0,
    desc:'慢速',
    field:'slow'
  },
  NORMAL:{
    type:1,
    desc:'正常',
    field:'normal'
  }
}

/**
 * 疗程状态
 */
export const TreatmentStatusEnum = {
  EVALUATIONING:createEnumItem(0,'评估中'),
  EVALUATION_FINISH:createEnumItem(1,'评估完成'),
  SPORTING:createEnumItem(2,'运动中'),
  SPORT_FINISH:createEnumItem(3,'运动完成'),
  SPORT_STOP:createEnumItem(4,'运动终止'),
  TREATMENT_FINISH:createEnumItem(5,'疗程结束'),
}

/**
 * 根据类型值获取枚举
 * @param {*} enumObj
 * @param {*} type
 * @returns
 */
export function getEnumByType(enumObj, type) {
  const enums = Object.values(enumObj).filter((item) => item.type == type);
  return enums.length > 0 ? enums[0] : null;
}
