import Token from "@/utils/Token";

export const WebSocketCommand = {
  evaluationCmd: {
    /**
     * 接收到前置机开始评估响应
     */
    START_EVA: "START_EVA",

    /**
     * 重新评估消息
     */
    MSG_START_EVA: "MSG_START_EVA",

    /**
     * 获取全量评估数据
     */
    UPDATE_EVALUATION_DATA: "UPDATE_EVALUATION_DATA",

    /**
     * 评估完成
     */
    FINISH_EVA: "FINISH_EVA",

    /**
     * 更新一般疼痛分值
     */
    UPDATE_NORMAL_PAIN: "UPDATE_NORMAL_PAIN",

    /**
     * 评估暂停
     */
    PAUSE_EVA: "PAUSE_EVA",
    /**
     *  评估继续
     */
    CONTINUE_EVA: "CONTINUE_EVA",
    /**
     * 评估继续失败
     */
    CONTINUE_EVA_EXCEPTION: "CONTINUE_EVA_EXCEPTION",
    /**
     * 前置机错误通知
     */
    MSG_ERROR_MSG: "MSG_ERROR_MSG",

    /**
     * 更新设备信息
     */
    UPDATE_DEVICE_INFO: "UPDATE_DEVICE_INFO",

    /**
     * 开始训练
     */
    START_SPORT: "START_SPORT",

    /**
     * 全量训练数据
     */
    UPDATE_SPORT: "UPDATE_SPORT",

    /**
     * 结束训练阶段
     */
    FINISH_SPORT: "FINISH_SPORT",

    /**
     * 结束训练
     * 此消息在所有训练阶段都结束后会自动发出
     */
    FINISH_ALL_SPORTS: "FINISH_ALL_SPORTS",

    /**
     *  暂停训练
     */
    PAUSE_SPORT: "PAUSE_SPORT",

    /**
     * 继续训练
     */
    CONTINUE_SPORT: "CONTINUE_SPORT",

    /**
     * 继续训练失败
     */
    CONTINUE_SPORT_EXCEPTION: "CONTINUE_SPORT_EXCEPTION",

    /**
     * 教学结束通知
     * 只有收到此消息后，时间进度才会进行增长
     */
    MSG_TRANSFER_FINISH_TRAIN: "MSG_TRANSFER_FINISH_TRAIN",

    /**
     * 同步训练时间
     */
    ASYNC_SPORTED_TIME: "ASYNC_SPORTED_TIME",

    /**
     * MSG_TRANSFER_FINISH_SF
     */
    MSG_TRANSFER_FINISH_SF: "MSG_TRANSFER_FINISH_SF",

    /**
     * 终止训练
     */
    STOP_SPORT: "STOP_SPORT"

  },
};

/**
 * websocket 业务
 */
export default {
  socketUrl: "",
  websocket: null,
  actionMap: {},
  isConnect: false,

  initConnect() {

    if (this.isConnect) {
      return
    }

    this.socketUrl = this.getWsUrl(
      `/api/hospital/websocket/${Token.getLoginUser().id}`
    );

    this.websocket = new WebSocket(this.socketUrl);
    this.websocket.onopen = () => {
      this.onOpen();
    };
    this.websocket.onclose = () => {
      this.onClose();
    };
    this.websocket.onerror = () => {
      this.onError();
    };
    this.websocket.onmessage = (e) => {
      this.onMessage(e);
    };
  },

  getWsUrl(wsUrl) {
    let protocol = "ws:";
    if (window.location.protocol === "https:") {
      protocol = "wss:";
    }
    return protocol + "//" + window.top.location.host + wsUrl;
  },
  onOpen() {
    this.isConnect = true
    console.log("WebSocket连接成功", this.socketUrl);
  },
  onClose() {
    this.isConnect = false
    console.log("WebSocket onClose", this.socketUrl);
  },
  onError() {
    this.isConnect = false
    console.log("WebSocket onError", this.socketUrl);
  },
  onMessage(event) {
    const data = JSON.parse(event.data);
    console.log("websoket receive data:", event.data, data);
    const listener = this.actionMap[data.code];
    if (listener) {
      try {
        listener.msgCall.call(listener.target, data);
      } catch (e) {
        console.log("监听对象异常");
      }
    }
  },
  addMessageListener(webSocketCmd, target, msgCall) {
    this.actionMap[webSocketCmd] = {
      target: target,
      msgCall: msgCall,
    };
  },
  removeMessageListener(webSocketCmd, target) {
    delete this.actionMap[webSocketCmd];
    console.log(target);
  },
};
