import { SportDirectionEnum,SportSpeedEnum, getEnumByType } from "@/utils/Enums";
import Moment from "moment";
import WebSocketService from "@/api/WebSocketService";

/**
 * 通用混合对象
 */
export const CommonMixin = {
  filters: {
    formatGender(value) {
      return value == 1 ? "女" : "男";
    },
    formatDate(value) {
      if (value && value.length>=10) {
        return value.substring(0, 10);
      }
      return value;
    },
    formatDateStyle(value, pattern) {
      if (value) {
        return Moment(value).format(pattern);
      }
      return value;
    },
    formatEmpty(value, defalutValue = "无") {
      if (value === null || value === "") {
        return defalutValue;
      }
      return value;
    },
    formatTel(value) {
      if (value && value.length == 11) {
        return (
          value.substring(0, 3) +
          " " +
          value.substring(3, 7) +
          " " +
          value.substring(7, 11)
        );
      }
      return value;
    },
    formatSportDirection(type) {
      const enumItem = getEnumByType(SportDirectionEnum, type);
      return enumItem != null ? enumItem.desc : null;
    },
    formatAngle(value) {
      if (value == null || value === undefined) {
        return "--";
      }
      return `${value}°`;
    },
    formatAngleSpeed(value, emptyValue = "--") {
      if (value === null || value === undefined) {
        return emptyValue;
      }
      return `${value.toFixed(1)}°/秒`;
    },
    formatSpeed(type){
      const enumItem = getEnumByType(SportSpeedEnum, type);
      return enumItem != null ? enumItem.desc : null;
    },
    formatSecond(value, emptyValue = "--", unit = "秒") {
      if (value === null) {
        return emptyValue;
      }
      return `${value.toFixed(0)}${unit}`;
    },
    formatSeconds(value, type) {
      if(value == null || value === undefined || value === '' || isNaN(value)){
        return "N/A";
      }
      if (!type) {
        type = 'default';
      } else {
        type = 'chinese';
      }
      let secondTime = parseInt(value);// 秒
      let minuteTime = 0;// 分
      let hourTime = 0;// 小时
      let secondTimeStr = "";
      let minuteTimeStr = "";
      let hourTimeStr = "";
      let result = "";
      let resultCn = "";
      if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
  
  
      if (secondTime < 10) {
        secondTimeStr = "0" + secondTime;
      } else {
        secondTimeStr = "" + secondTime;
      }
      if (minuteTime < 10) {
        minuteTimeStr = "0" + minuteTime;
      } else {
        minuteTimeStr = "" + minuteTime;
      }
      if (hourTime < 10) {
        hourTimeStr = "0" + hourTime;
      } else {
        hourTimeStr = "" + hourTime;
      }
      resultCn = secondTime + "秒";
  
      if (minuteTime >= 0) {
        result = minuteTimeStr + ":" + secondTimeStr;
        if (minuteTime > 0) {
          resultCn = minuteTime + "分" + resultCn;
        }
      }
      if (hourTime > 0) {
        result = hourTimeStr + ":" + result;
        resultCn = hourTime + "时" + resultCn;
      }
      if (type == 'default') {
        return result;
      } else if (type == 'chinese') {
        return resultCn;
      }
  
    }
  },
  mounted() {
    this.initPageParam();
  },
  methods: {
    navigateTo(url) {
      this.$router.push(url);
    },
    back() {
      this.$router.back();
    },
    initPageParam() {
      if (!this.pageParam) {
        return;
      }
      for (let key in this.pageParam) {
        this.pageParam[key] = this.$route.query[key];
      }
    },
    setPageTemplateTitle(title) {
      if (window.pageTemplate) {
        window.pageTemplate.pageTitle = title;
      }
    },
    hidePageTemplateBackBtn() {
      if (window.pageTemplate) {
        window.pageTemplate.showBack = false;
      }
    },
    showBackHomeButton(){
        window.$APP.showBackHomeButton()
    },
    hideBackHomeButton(){
        window.$APP.hideBackHomeButton()     
    },
    copyProperties(source, target) {
      Object.keys(target).forEach((key) => {
        if (source[key] !== undefined) {
          target[key] = source[key];
        }
      });
    },
  },
};

/**
 * 工作台相关业务混合对象
 */
export const WorkbenchMixins = {
  data() {
    return {};
  },
  mounted() {
    console.log("混入mounted");
    this.patientCaseId = this.$route.query.patientCaseId;
    this.treatmentId = this.$route.query.treatmentId;
    this.evaluationId = this.$route.query.evaluationId;
    //连接websocket
    WebSocketService.initConnect();
  },
  methods: {
    /**
     * 打开工作台界面
     * @param {*} patientCaseId 病程ID
     */
    openWorkbenchPage(patientCaseId) {
      const url = `/page/workbench?patientCaseId=${patientCaseId}`;
      this.navigateTo(url);
    },
    /**
     * 打开评估方案界面
     * @param {*} patientCaseId 病程ID
     * @param {*} treatmentId 疗程ID
     */
    openEvaluationSchemePage(patientCaseId, treatmentId) {
      const url = `/page/evaluation-scheme?patientCaseId=${patientCaseId}&treatmentId=${treatmentId}`;
      this.navigateTo(url);
    },
    /**
     * 打开评估中界面
     * @param {*} patientCaseId 病程ID
     * @param {*} treatmentId 疗程ID
     * @param {*} evaluationId 评估ID
     * @param {*} deviceId 设备ID
     */
    openEvaluationingPage(patientCaseId, treatmentId, evaluationId, deviceId) {
      const url = `/page/evaluationing?patientCaseId=${patientCaseId}&treatmentId=${treatmentId}&evaluationId=${evaluationId}&deviceId=${deviceId}`;
      this.navigateTo(url);
    },

    /**
     * 打开训练方案界面
     * @param {*} patientCaseId
     * @param {*} treatmentId
     */
    openTrainSchemePage(patientCaseId, treatmentId, patientId) {
      const url = `/page/train-scheme?patientCaseId=${patientCaseId}&treatmentId=${treatmentId}&patientId=${patientId}`;
      this.navigateTo(url);
    },
    /**
     * 打开训练中界面
     */
    openTrainingPage(patientCaseId, treatmentId, trainId, deviceId) {
      const url = `/page/Training?patientCaseId=${patientCaseId}&treatmentId=${treatmentId}&trainId=${trainId}&deviceId=${deviceId}`;
      this.navigateTo(url);
    },
  },
};

/**
 * 前置机通信相关
 */
export const MainServiceCommunication = {
  data() {
    return {
      vrRequestCommandTimeoutTask: null,
      vrRequestCommandTimeout: 20 * 1000,
      elLoading: null,
      loading: false,
    };
  },
  methods: {
    /**
     * 展示加载效果遮罩
     * @param {*}} loadingText 展示标题
     */
    showLoading(loadingText = "处理中") {
      this.loading = true
      this.elLoading = this.$loading({
        lock: true,
        text: loadingText,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
    },
    /**
     * 隐藏加载效果
     */
    hideLoading() {
      this.loading = false
      this.elLoading && this.elLoading.close();
    },
    /**
     * 开启超时任务
     * @param {*} timeoutCall 超时回调
     */
    openVrRequestTimeoutTask(timeoutCall) {
      this.showLoading();
      this.vrRequestCommandTimeoutTask = setTimeout(() => {
        this.hideLoading();
        this.loading = false;
        this.$alert("连接VR设备超时！", "警告", {
          confirmButtonText: "确定",
          callback: () => {
            timeoutCall && timeoutCall();
          },
        });
      }, this.vrRequestCommandTimeout);
    },
    /**
     * 清除VR超时任务
     */
    clearVrRequestTimeoutTask(){
      clearTimeout(this.vrRequestCommandTimeoutTask)
      this.hideLoading()
    }
  },
};

/**
 * 返回首页按钮控制
 */
export const BackHomeControlMixins = {
  
  mounted(){
    this.showBackHomeButton();
  },
  beforeDestroy(){
    this.hideBackHomeButton();
  }
}