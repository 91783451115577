import axios from "axios";
import { Message } from "element-ui";
// import router from "../router";

const service = axios.create({
  baseURL: "",
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

// 不循序重复请求 相同请求会阻塞
const pendingAjax = [];
const fastClickMsg = "数据请求中，请稍后";
const CancelToken = axios.CancelToken;
const removePendingAjax = (config, c) => {
  let url;
  if (config.url.indexOf(config.baseURL) >= 0) {
    url = config.url + config.method;
  } else {
    url = config.baseURL + config.url + config.method;
  }
  const index = pendingAjax.findIndex((i) => i === url);
  if (index > -1) {
    c ? c(fastClickMsg) : pendingAjax.splice(index, 1);
  } else {
    c && pendingAjax.push(url);
  }
};

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (config.method.toLowerCase !== "get".toLowerCase) {
      config.cancelToken = new CancelToken((c) => {
        removePendingAjax(config, c);
      });
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    removePendingAjax(response.config);
    const res = response.data;

    console.log("api res:", res);
    if (!res.ok) {
      Message({
        message: res.message || "Error",
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(new Error(res.message || "Error"));
    }

    return res;
  },
  (error) => {
    console.log(status);
    try{
      if (error.response.status === 401) {
        window.location.href='/index.html#/login'
      }
    }catch(e){
      window.location.href='/index.html#/login'
    }
    

    return Promise.reject(error);
  }
);

export default service;
