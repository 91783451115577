export default {
  loginStatusKey: "loginStatus",

  csrfTokenKey: "csrfToken",

  userStoreKey: "USER_INFO",

  /**
   * 是否登陆
   */
  isLogin() {
    return localStorage.getItem(this.loginStatusKey) === "1";
  },

  /**
   * 跳转到登录界面
   */
  jumpToLoginPage() {
    this.deleteToken();
    window.top.location.href = "/index.html#/login";
  },

  /**
   * 获取登录token
   * @returns
   */
  token() {
    return localStorage.getItem(this.csrfTokenKey);
  },

  /**
   * 删除token
   */
  deleteToken() {
    localStorage.removeItem(this.loginStatusKey);
    localStorage.removeItem(this.userStoreKey);
  },

  /**
   * 保存token
   * @param {*} loginUser
   */
  saveToken(loginUser) {
    localStorage.setItem(this.loginStatusKey, "1");
    localStorage.setItem(
      this.userStoreKey,
      this.utf8ToBase64(JSON.stringify(loginUser))
    );
    localStorage.setItem("sessionId",loginUser.jsessionid)
  },

  /**
   * 获取登录用户
   * @returns
   */
  getLoginUser() {
    if (this.isLogin()) {
      return JSON.parse(
        this.base64ToUtf8(localStorage.getItem(this.userStoreKey))
      );
    }
    this.jumpToLoginPage();
    return null;
  },

  utf8ToBase64(str) {
    return window.btoa(window.unescape(window.encodeURIComponent(str)));
  },

  base64ToUtf8(str) {
    return window.decodeURIComponent(window.escape(window.atob(str)));
  },
};
